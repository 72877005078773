<script>
export default {
  name: "MasterAccountRestriction",
};
</script>

<template>
  <section class="master-account-restriction">
    <div class="master-account-restriction__link">
      <span class="icon-link-new" />
    </div>
    <h3>Реферальные ссылки</h3>
    <p>
      Переключитесь на нижний аккаунт, чтобы использовать реферальные ссылки
    </p>
  </section>
</template>

<style lang="scss" scoped>
.master-account-restriction {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-radius: 16px;
  background: $light-second;

  &__link {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $dark-primary;
    margin-bottom: 16px;
    border-radius: 100%;
  }

  .icon-link-new {
    width: 30px;
    height: 30px;
    background: $light-primary;
  }

  h3 {
    @include title-3;
    color: $dark-primary;
    text-align: center;
    margin-bottom: 8px;
  }

  p {
    @include body-1;
    color: $dark-third;
    text-align: center;
  }
}

@media (min-width: 1024px) {
  .master-account-restriction {
    padding: 32px;
    width: 458px;

    &__link {
      width: 72px;
      height: 72px;
      margin-bottom: 24px;
    }

    .icon-link-new {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
