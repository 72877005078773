<script>
import { mapState, mapMutations, mapActions } from "vuex";
import MainInput from "@/components/helpers/MainInput.vue";
import MainButtonIcon from "@/package/components/MainButtonIcon.vue";
import store from "@/store";

export default {
  components: {
    MainButtonIcon,
    MainInput,
  },

  props: {
    promocode: {
      type: String,
      required: true,
    },

    referralLink: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState({
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      user: (state) => state.auth.user,
    }),

    isVisibleSubTitle() {
      if (
        this.selectedBinaryAccount.position === "master" &&
        this.selectedBinaryAccount.tariff.code === "vip_d"
      ) {
        return !(
          !!this.referralLink.urls.client ||
          !!this.referralLink.urls.distributor
        );
      }

      return false;
    },
  },

  methods: {
    ...mapActions({
      createNotification: "createNotification",
    }),

    copyCode() {
      navigator.clipboard.writeText(this.promocode).then(() => {
        this.createNotification({
          title: "Промокод скопирован",
          type: "success",
        });
      });
    },
  },
};
</script>

<template>
  <div class="promocode-preview">
    <MainInput
      v-model="promocode"
      label="Промокод"
      background="white"
      use-dashed-border
      readonly
    >
      <template #icon>
        <MainButtonIcon @click="copyCode">
          <span class="icon-copy-new" />
        </MainButtonIcon>
      </template>
    </MainInput>

    <p v-if="isVisibleSubTitle">
      Можно использовать только для подключения ТСП в RC&nbsp;CITY, RC&nbsp;POS
      и CAMO
    </p>
  </div>
</template>

<style lang="scss" scoped>
.promocode-preview {
  width: 100%;

  .main-input {
    margin-bottom: 10px;

    ::v-deep {
      label {
        @include text-2;
        color: $dark-fifth !important;
      }

      input {
        @include body-1-bold;
      }
    }
  }

  .icon-copy-new {
    min-width: 20px;
    height: 20px;
    background: $dark-primary;
  }

  .main-button-icon {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(10%);
    z-index: 100;
    width: fit-content;
    height: fit-content;
  }

  p {
    @include text-2;
    color: $dark-sixth;
  }
}

@media (min-width: 1024px) {
  .promocode-preview {
    max-width: 343px;
  }
}
</style>
