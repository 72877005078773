<script>
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";
import MainInput from "@/components/helpers/MainInput.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { MainButton, MainInput, ButtonRedirect },

  emits: ["close"],

  props: {
    url: {
      type: String,
      required: true,
    },

    emailText: {
      type: Object,
      required: true,
    },

    titleCopy: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      email: "",
      isShowMailTemplate: false,
    };
  },

  computed: {
    formattedUrl() {
      return encodeURIComponent(this.url);
    },

    isShowItemViber() {
      const userAgent = navigator.userAgent.toLowerCase();
      const regexCodeMobile = /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i;

      return regexCodeMobile.test(userAgent);
    },
  },

  methods: {
    ...mapActions({
      createNotification: "createNotification",
    }),

    share(type) {
      switch (type) {
        case "copy":
          navigator.clipboard.writeText(this.url).then(() => {
            this.createNotification({
              title: this.titleCopy,
              type: "success",
            });
          });
          break;
        case "telegram":
          window.open(`https://t.me/share/url?url=${this.formattedUrl}`);
          break;
        case "whatsapp":
          window.open(
            `https://api.whatsapp.com/send?text=${this.formattedUrl}`
          );
          break;
        case "vk":
          window.open(`https://vk.com/share.php?url=${this.formattedUrl}`);
          break;
        case "viber":
          window.open(`viber://forward?text=${this.formattedUrl}`);
          break;
        case "mail":
          window.open(
            `mailto:${this.email}?subject=${this.emailText.subject}&body=${this.emailText.body}`
          );
          break;
      }

      this.$emit("close");
    },
  },
};
</script>

<template>
  <div class="certificates-share">
    <template v-if="!isShowMailTemplate">
      <button
        class="certificates-share__item button-icon"
        @click="share('copy')"
      >
        <img src="../assets/icons/share/copy.svg" alt="" />
        Копировать
      </button>

      <button
        class="certificates-share__item button-icon"
        @click="share('whatsapp')"
      >
        <img src="../assets/icons/share/whatsapp.svg" alt="" />
        WhatsApp
      </button>

      <button class="certificates-share__item button-icon" @click="share('vk')">
        <img src="../assets/icons/share/vk.svg" alt="" />
        VK
      </button>

      <button
        class="certificates-share__item button-icon"
        @click="share('telegram')"
      >
        <img src="../assets/icons/share/telegram.svg" alt="" />
        Telegram
      </button>

      <button
        v-if="isShowItemViber"
        class="certificates-share__item button-icon"
        @click="share('viber')"
      >
        <img src="../assets/icons/share/viber.svg" alt="" />
        Viber
      </button>

      <button
        class="certificates-share__item button-icon"
        @click="isShowMailTemplate = true"
      >
        <img src="../assets/icons/share/mail.svg" alt="" />
        Почта

        <span class="icon-arrow" />
      </button>
    </template>

    <template v-else>
      <div class="certificates-share__mail">
        <ButtonRedirect
          class="certificates-share__button-redirect"
          :text="'Назад'"
          @click="isShowMailTemplate = false"
        />
        <MainInput v-model="email" :placeholder="'Еmail'" />
        <MainButton
          class="certificates-share__button-send"
          :title="'Отправить'"
          :color="'outlined'"
          :padding="'8px 24px'"
          @click="share('mail')"
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.certificates-share {
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__item {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 4px 16px;
    @include text-2;
    color: $dark-primary;

    .icon-arrow {
      width: 24px;
      height: 24px;
      background-color: $dark-third;
      transform: rotate(90deg);
      margin-left: auto;
    }

    > img {
      width: 40px;
      height: 40px;
    }

    &:hover {
      background-color: $light-second;
    }

    &:active {
      background-color: $light-primary;
    }
  }

  &__mail {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
  }

  &__button-redirect {
    @include text-2;
  }

  &__button-send {
    margin-left: auto;
  }
}
</style>
