<script>
import { mapState } from "vuex";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "NotMetConditionsBlock",
  components: { MainButton },

  data() {
    return {
      premiumTariffs: ["vip_d", "vip_gold_d"],
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),

    examNotPassed() {
      return (
        this.premiumTariffs.includes(this.user.tariff.code) &&
        this.user.latest_exam?.status !== "passed"
      );
    },

    isBuyTariff() {
      return (
        this.selectedBinaryAccount.tariff.code === "premium_d" &&
        this.user.tariff.code === "vip_d"
      );
    },

    image() {
      if (this.isShowCardNeedExam) {
        return require("../../assets/images/referal-exams-2.svg");
      }

      return require("../../assets/images/referal-buy-tariff-2.svg");
    },

    buttonText() {
      if (this.isShowCardNeedExam) {
        return "Перейти к обучению";
      }

      return "Купить тариф";
    },

    isShowCardNeedExam() {
      return this.examNotPassed && !this.isBuyTariff;
    },

    titleCard() {
      if (this.isShowCardNeedExam) {
        return "Пройдите обучение";
      }
      return "Подключайте больше";
    },

    linkServices() {
      return {
        rsCity: process.env.VUE_APP_FRONT_RCCITY,
        rsPos: process.env.VUE_APP_RCPOS_FRONT_URL,
        camo: process.env.VUE_APP_SAMO_FRONT_URL,
      };
    },
  },

  methods: {
    clickOnButton() {
      if (this.examNotPassed && !this.isBuyTariff) {
        this.$router.push({ name: "Study" });
        return;
      }

      this.$router.push({ name: "Tariffs" });
    },
  },
};
</script>

<template>
  <div class="not-met-conditions-block">
    <img :src="image" alt="image" />
    <h3>{{ titleCard }}</h3>

    <p v-if="isShowCardNeedExam">
      Для того, чтобы приглашать заведения на нашу платформу и регистрировать
      пользователей в САМО, необходимо пройти обучение и сдать экзамен
    </p>

    <p v-else>
      Перейдите на тариф <strong>Франшиза</strong>, чтобы подключать предприятия
      в
      <a :href="linkServices.rsCity" target="_blank">RC&nbsp;CITY</a>
      и <a :href="linkServices.rsPos" target="_blank">RC&nbsp;POS</a>, а также
      регистрировать пользователей в
      <a :href="linkServices.camo" target="_blank">САМО</a>
    </p>

    <MainButton :title="buttonText" color="gold" @click="clickOnButton" />
  </div>
</template>

<style lang="scss" scoped>
.not-met-conditions-block {
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: $light-second;

  img {
    max-width: 220px;
    margin: 0 auto 16px auto;
  }

  h3 {
    width: 100%;
    @include title-3;
    text-align: center;
    color: $dark-primary;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $space-s;

    > span {
      display: flex;
      align-items: center;
      gap: $space-s;
    }
  }

  p {
    width: 100%;
    @include body-1;
    text-align: center;
    color: $dark-third;
    margin-bottom: 24px;

    > a {
      color: $blue;
      text-decoration: none;
    }
  }
}

@media (min-width: 900px) {
  .not-met-conditions-block {
    padding: 32px;

    p {
      margin-bottom: 43px;
    }
  }
}

.icon-logo-yellow-rc-city,
.icon-logo-purple-rc-pos {
  width: 40px;
  height: 40px;

  @media (max-width: 1024px) {
    width: 32px;
    height: 32px;
  }
}
</style>
